import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import App from "./App";
import { ChakraProvider } from '@chakra-ui/react'
import "./index.css";
import reportWebVitals from './reportWebVitals';
import {   BrowserRouter as Router, } from "react-router-dom"

const rootElement = document.getElementById('root')
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <ChakraProvider>
      <Router>
        <App />
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
