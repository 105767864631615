import "./App.css";
import Login from "./pages/Login";
//import Dashboard from "./pages/Dashboard";
import React, {useState} from "react";
import { Routes, Route } from "react-router-dom";
import AcquisizioniCompletate from "./pages/Completate";
/*import Impostazioni from "./pages/Impostazioni"; */
import AcquisizioniProgrammate from "./pages/Programmazioni";
import NoMatch from "./pages/NoMatch";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";


export default function App() {
  //stato token
  const bearerToken = localStorage.getItem("bearerToken");
  const [token, setToken] = useState(bearerToken);
  //dati form login
  const [formData, setFormData] = useState({ username: "", password: "" });

  //gestisce login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let credentials = {
        username: formData.username,
        password: formData.password,
      }
      console.log(credentials);
      //debugger;
      //!API PER RICEVERE E SALVARE IL TOKEN DI ACCESSO
        /* const response = await apiClient.post('/login', credentials);
        const { token } = response.data;
        setAuthToken(token); */
        if (formData.username === "admin" && formData.password === "DevelopersEco23!") {
          localStorage.setItem("bearerToken", "dfefef");
          setToken("dfefef");
        }
       
        //return token;
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
  };

   //gestione logout
   const handleLogout = () => {
    localStorage.removeItem("bearerToken");
    setToken("");
  };

  if (!token) {
    return (
      <Login 
        formData={formData}
        setFormData={setFormData}
        onLogin={handleLogin}
      />
    );
  } else {
    return (
      <>
        <Navbar onLogout={handleLogout} />
        <Routes>
          <Route
            path="home"
            element={<Home onSetToken={setToken} />}
          />
          <Route path="Programmazioni" element={<AcquisizioniProgrammate />} />
          <Route path="Completate" element={<AcquisizioniCompletate />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </>
    );
  }

}