import Container from "./container";
export default function Home({ onSetToken }) {
  //gestione logout
  /* const handleLogout = () => {
    localStorage.removeItem("bearerToken");
    onSetToken("");
  }; */
  //UI
  return (
    <Container>
      {/* <Button colorScheme="red" size="md" onClick={handleLogout}>
        Logout
      </Button> */}
    </Container>
  );
}