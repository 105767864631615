import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  Image,
  Text,
  Link
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { Link as RouteLink } from "react-router-dom";

const NavLink = ({ text }) => (
  <Link>
    <Text fontSize="xl">{text}</Text>
  </Link>
);

export default function Navbar({ onLogout }) {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true
  });
  return (
    <Box
      as="section"
      pb={{
        base: "12",
        md: "24"
      }}
    >
      <Box as="nav" bg="bg-surface" boxShadow="sm">
        <Container
          py={{
            base: "4",
            lg: "5"
          }}
        >
          <HStack spacing="10" justify="space-between">
            <Image
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/Logo_of_RAI_%282016%29.svg/1024px-Logo_of_RAI_%282016%29.svg.png"
              h={8}
              w={8}
            />
            {isDesktop ? (
              <Flex justify="space-between" flex="1">
                <RouteLink to="/Programmazioni">
                  <NavLink text="Programmazioni" />
                </RouteLink>
                <RouteLink to="/Completate">
                  <NavLink text="Acquisizioni Completate" />
                </RouteLink>
                <HStack spacing="3">
                  {/*  <Button variant="ghost">Login</Button> */}
                  <Button variant="blue" onClick={onLogout}>
                    Logout
                  </Button>
                </HStack>
              </Flex>
            ) : (
              <IconButton
                variant="ghost"
                icon={<FiMenu fontSize="1.25rem" />}
                aria-label="Open Menu"
              />
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  );
}
