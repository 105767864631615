import { Box, Text, Stack, Heading, useColorModeValue } from "@chakra-ui/react";

export default function AcquisizioniProgrammate() {
  return (
    <Box
      as="main"
      p={14}
      minH="25rem"
      bg={useColorModeValue("auto", "gray.800")}
    >
      <Stack
        direction={{ base: "column", sm: "row" }}
        alignItems="center"
        justifyContent="center"
        h="100%"
      >
        <Stack spacing={8}>
          <Box>
            <Heading color="blue.400" fontSize="3xl">
              Acquisizioni Programmate
            </Heading>
            <Text fontSize="md" color="gray.500">
              Quota disponibile: 180 di 220
            </Text>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
